/* eslint-disable react/prop-types */
import React from 'react'
import ReactSlider from 'react-slider'

class SweetSlider extends React.PureComponent {
  static defaultProps = {
    className: '',
    textColor: 'rgba(0, 0, 0, 0.65)',
    sliderColor: '#1582e6',
    dropShadow: true,
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  render() {
    const classNamesString = ['sweet-slider', this.props.className].join(' ')

    const sliderInnerClasses = ['sweet-slider-inner']
    this.props.dropShadow && sliderInnerClasses.push('drop-shadow')
    const sliderInnerClassesString = sliderInnerClasses.join(' ')

    return (
      <div className={classNamesString}>
        <div className='sweet-slider-title' style={{ color: this.props.textColor }}>{this.props.title + ': ' + this.props.value}</div>
        <div className='sweet-slider-wrapper' onClick={this.stopPropagation}>
          <ReactSlider
            className={sliderInnerClassesString}
            thumbClassName='sweet-slider-thumb'
            trackClassName='sweet-slider-track'
            onChange={this.props.onChange}
            value={this.props.value}
            min={parseInt(this.props.min)}
            max={parseInt(this.props.max)}
            renderThumb={(props) => <div {...props} style={{ ...props.style, borderColor: this.props.sliderColor }} />}
            renderTrack={(props) => <div {...props} style={{ ...props.style, backgroundColor: this.props.sliderColor }} />} />
        </div>
      </div>
    )
  }
}

export default SweetSlider