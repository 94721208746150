const drumsNicknames = {
    'none': '-Disable-',
    'drums_1': 'Catch',
    'drums_2': 'Kitkat',
    'drums_3': 'Lack',
    'drums_4': 'Hurl',
    'drums_5': 'Band',
    'drums_6': 'Worm',
    'drums_7': 'Cash',
    'drums_8': 'Ready',
    'drums_9': 'Filter',
    'drums_10': 'Sodium',
    'drums_11': 'Coma',
    'drums_12': 'Thesis',
    'drums_13': 'Hunter',
    'drums_14': 'Outlet',
    'drums_15': 'Notion',
    'drums_16': 'Gaffe',
    'drums_17': 'Excuse',
    'drums_18': 'Echo',
    'drums_19': 'Thick',
    'drums_20': 'Safari',
    'drums_21': 'Huge',
    'drums_22': 'Knock',
    'drums_23': 'Disk',
    'drums_24': 'Wing',
    'drums_25': 'Boy',
    'drums_26': 'Show',
    'drums_27': 'Stride',
    'drums_28': 'Clay',
    'drums_29': 'Berry',
    'drums_30': 'Chase',
}

const arpeggiosNicknames = {
    'arpeggio_1': 'Clean',
    'arpeggio_2': 'Rich',
    'arpeggio_3': 'Rainy',
    'arpeggio_4': 'Ultra',
    'arpeggio_5': 'Swifty',
    'arpeggio_6': 'Fine',
    'arpeggio_7': 'Seemly',
    'arpeggio_8': 'Sweet',
    'arpeggio_9': 'Worried',
    'arpeggio_10': 'Spicy',
    'arpeggio_11': 'Immense',
    'arpeggio_12': 'Melted',
    'arpeggio_13': 'Cautious',
    'arpeggio_14': 'Rude',
    'arpeggio_15': 'Nervous',
    'arpeggio_16': 'Cute',
    'arpeggio_17': 'Brief',
    'arpeggio_18': 'Thick',
    'arpeggio_19': 'Petite',
    'arpeggio_20': 'Pumped',
    'arpeggio_21': 'Snobbish',
    'arpeggio_22': 'Ritzy',
    'arpeggio_23': 'Jobless',
    'arpeggio_24': 'Young',
    'arpeggio_25': 'Quirky',
    'arpeggio_26': 'Abashed',
    'arpeggio_27': 'Burish',
    'arpeggio_28': 'Nimble',
    'arpeggio_29': 'Jaded',
    'arpeggio_30': 'Useful',
}

export { drumsNicknames, arpeggiosNicknames }