/* eslint-disable react/prop-types */
import React from 'react'
import Head from 'next/head'
import Header from '../components/ui/Header'
import Footer from '../components/ui/Footer'
import MobileApps from '../components/ui/MobileApps'
import PianoView from '../components/ui/PianoView'
import SweetHorizontalPicker from '../components/ui/SweetHorizontalPicker'
import SweetSlider from '../components/ui/SweetSlider'
import SweetRoundButton from '../components/ui/SweetRoundButton'
import SweetModal from '../components/ui/SweetModal'
import EditMenuIcon from '../components/ui/EditMenuIcon'
import MobileAppsModal from '../components/ui/MobileAppsModal'
import LoginModal from '../components/ui/LoginModal'
import ShareModal from '../components/ui/ShareModal'
import LimitedModal from '../components/ui/LimitedModal'
import InstrumentModal from '../components/ui/InstrumentModal'
import OptionSelect from '../components/ui/OptionSelect'
import ChordBlock from '../components/ui/ChordBlock'
import WelcomeText from '../components/ui/WelcomeText'
import { withUserAgent } from 'next-useragent'
import BrowserIsNotSupported from '../components/ui/BrowserIsNotSupported'
import { Checkbox, Spin, Tooltip, message } from 'antd'
// import SidePopupTuner from '../components/ui/SidePopupTuner'
// import SidePopupSample from '../components/ui/SidePopupSample'
import '../styles/style.scss'

import { arpeggiosNicknames, drumsNicknames } from '../components/helper/ArpeggioDrumsNames'
import { SoundHelper, isToneSupported } from '../components/helper/SoundHelper'
import * as LogHelper from '../components/helper/LogHelper'
import * as AudioFunctions from '../components/helper/AudioFunctions'
import axios from 'axios'
import { SynthInstruments, DrumsInstruments, SYNTH_TYPE } from '../components/helper/Instruments'
import IOSAudioComponentFix from '../components/ui/IOSAudioComponentFix'
import SidePopupGenerator from '../components/ui/SidePopupGenerator'

Array.prototype.randomChoice = function () {
  return this[Math.floor(Math.random() * this.length)]
}

const UNLOCKED_ARPS_COUNT = 9
const UNLOCKED_DRUMS_COUNT = 10

const modeValueDict = {
  'All Modes': 'all',
  'Major': 'major',
  'Minor': 'natural_minor',
}

const keys = ['C', 'C#/Db', 'D', 'D#/Eb', 'E', 'F', 'F#/Gb', 'G', 'G#/Ab', 'A', 'A#/Bb', 'B']

const arpeggiosLabels = Object.values(arpeggiosNicknames)
const drumsLabels = Object.values(drumsNicknames)

const arpeggiosIds = Object.keys(arpeggiosNicknames)
const unlockedArpeggiosIds = arpeggiosIds.slice(0, UNLOCKED_ARPS_COUNT)

const drumsIds = Object.keys(drumsNicknames)
const unlockedDrumsIds = drumsIds.slice(0, UNLOCKED_DRUMS_COUNT)

const modeValueDictNames = Object.keys(modeValueDict)

const keyboardMappings = {
  'Q': 0, '2': 1, 'W': 2, '3': 3, 'E': 4, 'R': 5, '5': 6, 'T': 7, '6': 8, 'Y': 9, '7': 10, 'U': 11, 'I': 12, '9': 13, 'O': 14, '0': 15, 'P': 16,
  'Z': 12, 'S': 13, 'X': 14, 'D': 15, 'C': 16, 'V': 17, 'G': 18, 'B': 19, 'H': 20, 'N': 21, 'J': 22, 'M': 23,
}

const chordTypes = ['M', 'm', '7', 'm7', 'maj7', 'dim', 'sus2', 'sus4']

let bpmTimerId = 0
let isAudioContextStarted = false

class Generator extends React.PureComponent {
  soundHelper = new SoundHelper()

  static async getInitialProps({ req, query: {
    isUserLoggedIn,
    isUserProSubscribed,
    userDisplayName,
    userEmail,
    userPremiumPlan,
    userExpiryDate,
    progressionJson,
    generatorOptions,
    isProgressionSaved,
  } }) {
    const ip = req && req.headers && req.headers['x-forwarded-for']
      ? req.headers['x-forwarded-for'].split(',')[0].trim() : ''

    return {
      ip,
      isUserLoggedIn,
      isUserProSubscribed,
      userDisplayName,
      userEmail,
      userPremiumPlan,
      userExpiryDate,
      progressionJson,
      generatorOptions,
      isProgressionSaved,
    }
  }

  state = {
    isFetchingApi: false,
    isFetchingSoundHelper: true,
    isPlaying: false,
    isChordEditOpened: false,
    isLoginModalOpened: false,
    isShareModalOpened: false,
    isLimitedModalOpened: false,
    isInstrumentModalOpened: false,
    isMobileAppsModalOpened: false,
    limitedText: '',
    chordEditKey: -1,
    chordEditType: -1,
    isGeneratorSettingsOpened: false,
    isGeneratorRandomKey: this.props.generatorOptions.isRandomKey,
    isGeneratorRandomBpm: this.props.generatorOptions.isRandomBpm,
    isGeneratorRandomArpeggio: this.props.generatorOptions.isRandomArpeggio,
    isGeneratorRandomDrums: this.props.generatorOptions.isRandomDrums,
    generatorMode: this.props.generatorOptions.mode && modeValueDictNames.includes(this.props.generatorOptions.mode)
      ? this.props.generatorOptions.mode : 'Major',
    currentChordIndex: -1,
    currentBpm: this.props.progressionJson.bpm,
    currentSynthVolume: Number.isInteger(this.props.generatorOptions.synthVolume) ? this.props.generatorOptions.synthVolume : 90,
    currentDrumsVolume: Number.isInteger(this.props.generatorOptions.drumsVolume) ? this.props.generatorOptions.drumsVolume : 80,
    // Migration from numbers to strings
    currentSynthId: this.props.generatorOptions.synthInstrument in SynthInstruments ? this.props.generatorOptions.synthInstrument : '0',
    currentDrumsId: this.props.generatorOptions.synthInstrument in DrumsInstruments ? this.props.generatorOptions.drumsInstrument : '0',
    currentKeyIndex: this.props.progressionJson.scale.note.midi % 12,
    touchedNotesSet: new Set(),
    progressionJson: this.props.progressionJson,
    isProgressionSaved: this.props.isProgressionSaved,
    previewIdLoading: -1,
    previewIdPlaying: -1,
    previewIdType: SYNTH_TYPE,
  }

  constructor(props) {
    super(props)
    this.IOSAudioComponentFixRef = React.createRef()
  }

  componentDidMount() {
    LogHelper.init()

    if (!isToneSupported) {
      return
    }

    this.soundHelper.init(this.onSoundHelperLoadCallback, this.onSoundHelperChordCallback)
    this.setDrumsArpeggio(this.props.progressionJson)
    this.soundHelper.setBpm(this.state.currentBpm)
    this.soundHelper.setVolume(this.state.currentSynthVolume, this.state.currentDrumsVolume)
    this.soundHelper.setSynthInstrument(this.state.currentSynthId)
    this.soundHelper.setDrumsInstrument(this.state.currentDrumsId)

    document.addEventListener('keydown', this.onKeyboardDown)
    document.addEventListener('keyup', this.onKeyboardUp)

    // eslint-disable-next-line no-undef
    // Paddle.Setup({
    //   vendor: 39907,
    // })
  }

  interactionHappened(value) {
    if (!isAudioContextStarted && isToneSupported) {
      this.soundHelper.start()
      this.IOSAudioComponentFixRef.current.start()
      isAudioContextStarted = true
    }

    LogHelper.logCategory('Interaction', value)
  }

  currentKeySelected = (key) => {
    this.setState({ currentKeyIndex: key })
    this.fetchChangeKey(key)
  }

  currentArpeggioSelected = (arpeggioIndex) => {
    if (this.props.isUserProSubscribed || !this.props.isUserProSubscribed && arpeggioIndex < UNLOCKED_ARPS_COUNT) {
      this.fetchChangePlayback(arpeggiosIds[arpeggioIndex], this.state.progressionJson.drumsId)
    } else {
      this.openLimitedModal('Want More Arpeggios?')
    }
  }

  currentDrumsSelected = (drumsIndex) => {
    if (this.props.isUserProSubscribed || !this.props.isUserProSubscribed && drumsIndex < UNLOCKED_DRUMS_COUNT) {
      this.fetchChangePlayback(this.state.progressionJson.arpeggioId, drumsIds[drumsIndex])
    } else {
      this.openLimitedModal('Want More Drums?')
    }
  }

  generatorModeSelected = (key) => {
    this.setState({ generatorMode: modeValueDictNames[key] })
  }

  randomKeyChange = (event) => {
    this.setState({ isGeneratorRandomKey: event.target.checked })
  }

  randomBpmChange = (event) => {
    this.setState({ isGeneratorRandomBpm: event.target.checked })
  }

  randomArpeggioChange = (event) => {
    this.setState({ isGeneratorRandomArpeggio: event.target.checked })
  }

  randomDrumsChange = (event) => {
    this.setState({ isGeneratorRandomDrums: event.target.checked })
  }

  onChordBlockClick = (index) => {
    this.interactionHappened('Chord Click')
    this.setState({ currentChordIndex: index })
    this.stopPlaying()
    this.soundHelper.playSingleChord(this.state.progressionJson.chords[index])
  }

  onChordEditClick = (index) => {
    this.interactionHappened('Chord Edit Click')
    this.stopPlaying()
    this.setState({
      isChordEditOpened: true,
      chordEditKey: this.state.progressionJson.chords[index].notes[0].midi % 12,
      chordEditType: chordTypes.indexOf(this.state.progressionJson.chords[index].type),
    })
  }

  onChordEditKeySelected = (index) => {
    this.setState({ chordEditKey: index })
    this.fetchChangeChord(this.state.currentChordIndex, index, this.state.chordEditType)
  }

  onChordEditTypeSelected = (index) => {
    this.setState({ chordEditType: index })
    this.fetchChangeChord(this.state.currentChordIndex, this.state.chordEditKey, index)
  }

  onChordEditModalBack = () => {
    this.setState({ isChordEditOpened: false })
  }

  onShareModalBack = () => {
    this.setState({ isShareModalOpened: false })
  }

  onInstrumentModalBack = () => {
    this.setState({ isInstrumentModalOpened: false, previewIdLoading: -1, previewIdPlaying: -1 })
    this.soundHelper.stopOneShot()
    this.fetchSaveGeneratorOptions()
  }

  onInstrumentChangeClick = () => {
    this.interactionHappened('Instrument Change Click')
    this.setState({ isInstrumentModalOpened: true })
  }

  setSynthInstrument = (id) => {
    this.soundHelper.setSynthInstrument(id)
    this.setState({
      currentSynthId: id,
      isFetchingSoundHelper: !this.soundHelper.isAllSoundsLoaded(),
    })
  }

  setDrumsInstrument = (id) => {
    this.soundHelper.setDrumsInstrument(id)
    this.setState({
      currentDrumsId: id,
      isFetchingSoundHelper: !this.soundHelper.isAllSoundsLoaded(),
    })
  }

  onChangeSynthInstrument = (id, synthInstrument) => {
    this.interactionHappened('Synth Changed')
    if (this.props.isUserProSubscribed || !synthInstrument.isPremium) {
      this.setSynthInstrument(id)
    } else if (!this.props.isUserLoggedIn) {
      this.openLoginModal()
    } else {
      this.openLimitedModal('Want More Instruments?')
    }
  }

  onChangeDrumsInstrument = (id, drumsInstrument) => {
    this.interactionHappened('Drums Changed')
    if (this.props.isUserProSubscribed || !drumsInstrument.isPremium) {
      this.setDrumsInstrument(id)
    } else if (!this.props.isUserLoggedIn) {
      this.openLoginModal()
    } else {
      this.openLimitedModal('Want More Instruments?')
    }
  }

  onChangeSynthVolume = (volume) => {
    this.interactionHappened('Change Synth Volume')
    this.setState({ currentSynthVolume: volume })
    this.soundHelper.setVolume(volume, this.state.currentDrumsVolume)
  }

  onChangeDrumsVolume = (volume) => {
    this.interactionHappened('Change Drums Volume')
    this.setState({ currentDrumsVolume: volume })
    this.soundHelper.setVolume(this.state.currentSynthVolume, volume)
  }

  onPreviewPlayPauseClick = (idType, id) => {
    this.stopPlaying()
    if (id === this.state.previewIdPlaying && idType === this.state.previewIdType) {
      this.setState({ previewIdPlaying: -1 })
      this.soundHelper.stopOneShot()
    } else {
      this.setState({ previewIdType: idType, previewIdLoading: id })

      let previewUrl, configUrl = ''

      if (idType === SYNTH_TYPE) {
        previewUrl = `/static/sounds/new_synths/${SynthInstruments[id].folder}/preview.mp3`
        configUrl = `/static/sounds/new_synths/${SynthInstruments[id].folder}/instrument.json`
      } else {
        previewUrl = `/static/sounds/new_drums/${DrumsInstruments[id].folder}/preview.mp3`
        configUrl = `/static/sounds/new_drums/${DrumsInstruments[id].folder}/instrument.json`
      }

      this.soundHelper.playOneShot(configUrl, previewUrl, () => {
        this.setState({ previewIdPlaying: id, previewIdLoading: -1 })
      }, () => {
        this.setState({ previewIdPlaying: -1 })
      })
    }
  }

  onChangeBpm = (bpm) => {
    this.interactionHappened('Change Bpm')
    this.setState({ currentBpm: bpm })
    this.soundHelper.setBpm(bpm)
    clearTimeout(bpmTimerId)
    bpmTimerId = setTimeout(() => {
      this.fetchBpmChange()
    }, 500) // 500ms timing to check whether bpm changed again.
  }

  onPlayPauseClick = () => {
    this.interactionHappened('Play/Pause')
    const newPlaying = !this.state.isPlaying
    this.setState({ isPlaying: newPlaying })
    if (newPlaying) {
      this.soundHelper.play()
    } else {
      this.soundHelper.stop()
    }
  }

  onGenerateClick = () => {
    this.interactionHappened('Generate')
    this.fetchGenerateProgression()
  }

  onGeneratorSettingsClick = () => {
    this.interactionHappened('Generator Settings')
    this.stopPlaying()
    this.setState({ isGeneratorSettingsOpened: true })
  }

  onGeneratorSettingsModalBack = () => {
    this.fetchSaveGeneratorOptions()
    this.setState({ isGeneratorSettingsOpened: false })
  }

  onPrintProgressionClick = () => {
    this.interactionHappened('Print Chords')
    this.stopPlaying()

    if (!this.props.isUserLoggedIn) {
      this.openLoginModal()
    } else if (!this.props.isUserProSubscribed) {
      this.openLimitedModal('Want To Print Chords?')
    } else {
      this.setState({ isFetchingSoundHelper: true })
      import('../components/helper/PDFHelper').then(pdfHelper => {
        pdfHelper.exportPdf('Progression.pdf', this.state.progressionJson.chords)
        this.setState({ isFetchingSoundHelper: false })
      })
    }
  }

  onExportMidiClick = () => {
    this.interactionHappened('Export Midi')
    this.stopPlaying()
    if (!this.props.isUserLoggedIn) {
      this.openLoginModal()
    } else if (!this.props.isUserProSubscribed) {
      this.openLimitedModal('Want MIDI Export?')
    } else {
      this.setState({ isFetchingSoundHelper: true })
      axios.get('/api/export-midi', {
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Progression.mid')
        document.body.appendChild(link)
        link.click()
        this.setState({ isFetchingSoundHelper: false })
      })
    }
  }

  onExportMp3Click = () => {
    this.interactionHappened('Export Mp3')
    this.stopPlaying()
    if (!this.props.isUserLoggedIn) {
      this.openLoginModal()
    } else if (!this.props.isUserProSubscribed) {
      this.openLimitedModal('Want Mp3 Export?')
    } else {
      this.setState({ isFetchingSoundHelper: true })
      this.soundHelper.exportBuffer((buffer) => {
        AudioFunctions.floatBufferToBlobMp3(buffer, 'Progression.mp3', () => {
          this.setState({ isFetchingSoundHelper: false })
        })
      })
    }
  }

  onShareProgressionClick = () => {
    this.interactionHappened('Share Progression')
    if (this.state.isFetchingApi) {
      return
    }
    this.stopPlaying()
    if (!this.props.isUserLoggedIn) {
      this.openLoginModal()
    } else {
      this.fetchShareProgression()
    }
  }

  onSaveDeleteProgressionClick = () => {
    this.interactionHappened('Save/Delete Progression')
    if (this.state.isFetchingApi) {
      return
    }
    this.stopPlaying()
    if (!this.props.isUserLoggedIn) {
      this.openLoginModal()
    } else {
      if (this.state.isProgressionSaved) {
        this.fetchDeleteUserProgression()
      } else {
        this.fetchSaveTempProgression()
      }
    }
  }

  onMobileAppsModalBack = () => {
    this.setState({ isMobileAppsModalOpened: false })
  }

  openLimitedModal = (text) => {
    this.stopPlaying()
    this.setState({ isLimitedModalOpened: true, limitedText: text })
    LogHelper.showLimitedDialog()
  }

  openLoginModal = () => {
    this.setState({ isLoginModalOpened: true })
    LogHelper.showLoginDialog()
  }

  onLimitedModalBack = () => {
    this.setState({ isLimitedModalOpened: false })
  }

  onLoginModalBack = () => {
    this.setState({ isLoginModalOpened: false })
  }

  onKeyboardDown = (event) => {
    if (event.target.tagName !== 'BODY' || !event.key) {
      return
    }
    if (event.key.toUpperCase() in keyboardMappings && !event.repeat) {
      let lowMidi = keyboardMappings[event.key.toUpperCase()]
      this.soundHelper.onPressPianoNote(lowMidi)

      let touchedNotesSet = this.state.touchedNotesSet
      touchedNotesSet.add(lowMidi)
      this.setState({ touchedNotesSet: touchedNotesSet })
    } else if (event.code === 'Space') {
      this.onPlayPauseClick()
      event.preventDefault()
    }
  }

  onKeyboardUp = (event) => {
    if (event.target.tagName !== 'BODY' || !event.key) {
      return
    }
    if (event.key.toUpperCase() in keyboardMappings) {
      let lowMidi = keyboardMappings[event.key.toUpperCase()]
      this.soundHelper.onReleasePianoNote(lowMidi)

      let touchedNotesSet = this.state.touchedNotesSet
      touchedNotesSet.delete(lowMidi)
      this.setState({ touchedNotesSet: touchedNotesSet })
    }
  }

  onDisableAdsClick = () => {
    if (!this.props.isUserLoggedIn) {
      this.openLoginModal()
    } else if (!this.props.isUserProSubscribed) {
      this.openLimitedModal('Want Disable Ads?')
    }
  }

  onSoundHelperLoadCallback = () => {
    this.setState({ isFetchingSoundHelper: false })
  }

  onSoundHelperChordCallback = (chordIndex) => {
    this.setState({ currentChordIndex: chordIndex })
  }

  fetchGenerateProgression = () => {
    if (this.state.isFetchingApi) {
      return
    }
    const newKeyIndex = this.state.isGeneratorRandomKey ?
      this.getRandom(0, 12) : this.state.currentKeyIndex

    const newArpeggioId = this.state.isGeneratorRandomArpeggio ?
      (this.props.isUserProSubscribed ? arpeggiosIds.randomChoice() : unlockedArpeggiosIds.randomChoice())
      : this.state.progressionJson.arpeggioId

    const newDrumsId = this.state.isGeneratorRandomDrums ?
      (this.props.isUserProSubscribed ? drumsIds.randomChoice() : unlockedDrumsIds.randomChoice())
      : this.state.progressionJson.drumsId

    const newBpm = this.state.isGeneratorRandomBpm ?
      this.getRandom(80, 155 + 1) : this.state.currentBpm

    this.setState({
      isFetchingApi: true,
      currentKeyIndex: newKeyIndex,
      currentBpm: newBpm,
    })
    axios.get('/api/get-progression', {
      params: {
        root: newKeyIndex,
        generator_mode: modeValueDict[this.state.generatorMode],
        arpeggio_id: newArpeggioId,
        drums_id: newDrumsId,
      },
    }).then(res => {
      // CHORDCHORD-D1, CHORDCHORD-94
      LogHelper.logSentry('res.data:', res.data)
      this.setState({
        progressionJson: res.data,
        isProgressionSaved: false,
        isFetchingApi: false,
      })
      this.setDrumsArpeggio(res.data)
      this.restartSoundHelper(true)
      this.fetchBpmChange()
    })
  }

  fetchChangeKey = (key) => {
    if (this.state.isFetchingApi) {
      return
    }
    this.setState({
      isFetchingApi: true,
    })
    axios.get('/api/change-key', {
      params: { root: key },
    }).then(res => {
      // CHORDCHORD-D1
      LogHelper.logSentry('res.data:', res.data)
      this.setState({
        progressionJson: res.data,
        isFetchingApi: false,
      })
      this.setDrumsArpeggio(res.data)
      this.restartSoundHelper(false)
    })
  }

  fetchChangeChord = (chordIndex, chordKey, chordTypeIndex) => {
    if (this.state.isFetchingApi) {
      return
    }
    this.setState({
      isFetchingApi: true,
    })
    axios.get('/api/change-chord', {
      params: {
        chord_index: chordIndex,
        chord_root_value: chordKey,
        chord_type: chordTypes[chordTypeIndex],
      },
    }).then(res => {
      // CHORDCHORD-D1
      LogHelper.logSentry('res.data:', res.data)
      this.setState({
        progressionJson: res.data,
        isFetchingApi: false,
      }, () => {
        this.soundHelper.playSingleChord(this.state.progressionJson.chords[this.state.currentChordIndex])
        this.setDrumsArpeggio(this.state.progressionJson)
      })
    })
  }

  fetchChangePlayback = (arpeggioId, drumsId) => {
    if (this.state.isFetchingApi) {
      return
    }
    this.setState({
      isFetchingApi: true,
    })
    axios.get('/api/change-playback', {
      params: {
        arpeggio_id: arpeggioId,
        drums_id: drumsId,
      },
    }).then(res => {
      this.setState({
        progressionJson: res.data,
        isFetchingApi: false,
      })
      // CHORDCHORD-DY
      LogHelper.logSentry('res.data:', res.data)
      this.setDrumsArpeggio(res.data)
    })
  }

  fetchShareProgression = () => {
    if (this.state.isFetchingApi) {
      return
    }
    this.setState({
      isFetchingApi: true,
    })
    axios.get('/preview/share').then(res => {
      this.setState({
        previewUrl: `https://chordchord.com/preview/${res.data}`,
        isShareModalOpened: true,
        isFetchingApi: false,
      })
    })
  }

  fetchBpmChange = () => {
    if (this.state.isFetchingApi)
      return
    axios.post('/api/change-bpm', {
      bpm: this.state.currentBpm,
    })
  }

  fetchSaveGeneratorOptions = () => {
    axios.post('/api/save-generator-options', {
      isRandomKey: this.state.isGeneratorRandomKey,
      isRandomBpm: this.state.isGeneratorRandomBpm,
      isRandomArpeggio: this.state.isGeneratorRandomArpeggio,
      isRandomDrums: this.state.isGeneratorRandomDrums,
      mode: this.state.generatorMode,
      synthVolume: this.state.currentSynthVolume,
      drumsVolume: this.state.currentDrumsVolume,
      synthInstrument: this.state.currentSynthId,
      drumsInstrument: this.state.currentDrumsId,
    })
  }

  fetchSaveTempProgression = () => {
    this.setState({ isFetchingApi: true })
    axios.get('/api/save-temp-progression').then(response => {
      this.setState({ isFetchingApi: false })
      if (response.data === 'OK') {
        this.setState({ isProgressionSaved: true })
        message.success('Progression has been saved to your Library!')
      } else if (response.data === 'LIMIT') {
        this.openLimitedModal('Want More Saved Progressions?')
      }
    })
  }

  fetchDeleteUserProgression = () => {
    this.setState({ isFetchingApi: true })
    axios.get('/api/delete-user-progression').then(response => {
      this.setState({ isFetchingApi: false })
      if (response.data === 'OK') {
        this.setState({ isProgressionSaved: false })
        message.info('Progression has been removed from your Library!')
      }
    })
  }

  stopPlaying = () => {
    this.soundHelper.stop()
    this.setState({ isPlaying: false })
  }

  restartSoundHelper = (withForce) => {
    if (this.state.isPlaying || withForce) {
      this.setState({ isPlaying: true })
      this.soundHelper.stop()
      this.soundHelper.setBpm(this.state.currentBpm)
      this.soundHelper.play()
    }
  }

  setDrumsArpeggio = (progressionJson) => {
    this.soundHelper.setDrums(progressionJson.drums)
    this.soundHelper.setArpeggio(progressionJson.arpeggio, progressionJson.chords)
  }

  //Generates random number in range min (included) max (excluded)
  getRandom = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min
  }

  render() {
    if (!isToneSupported) {
      return (
        <React.Fragment>
          <Head>
            <title>ChordChord: Legacy Chord Progression Generator</title>
            <meta name='description' content='ChordChord Legacy is an old version of chord progression generator!  Make music online with one click...' />
            <meta property='og:image' content='https://legacy.chordchord.com/static/images/logo_icon.png' />
            <link rel='canonical' href='https://legacy.chordchord.com' />
          </Head>
          <Header isUserLoggedIn={this.props.isUserLoggedIn} isUserProSubscribed={this.props.isUserProSubscribed} />
          <BrowserIsNotSupported />
          <WelcomeText />
          <MobileApps />
          <Footer />
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <Head>
          <title>ChordChord: Legacy Chord Progression Generator</title>
          <meta name='description' content='ChordChord Legacy is an old version of chord progression generator! Make music online with one click...' />
          <meta property='og:image' content='https://legacy.chordchord.com/static/images/logo_icon.png' />
          <link rel='canonical' href='https://legacy.chordchord.com' />
          {/* Think where to put it so it loads faster. */}
          {/* <script src='https://cdn.paddle.com/paddle/paddle.js'></script> */}
        </Head>
{/* 
        <SidePopupSample />
        <SidePopupTuner /> */}
        <SidePopupGenerator />

        <IOSAudioComponentFix ref={this.IOSAudioComponentFixRef} />

        <MobileAppsModal onCancel={this.onMobileAppsModalBack} isOpened={this.state.isMobileAppsModalOpened} />

        <ShareModal onCancel={this.onShareModalBack} isOpened={this.state.isShareModalOpened} url={this.state.previewUrl && this.state.previewUrl || ''} />

        <InstrumentModal onCancel={this.onInstrumentModalBack}
          isOpened={this.state.isInstrumentModalOpened}
          synthVolume={this.state.currentSynthVolume}
          drumsVolume={this.state.currentDrumsVolume}
          onChangeSynthVolume={this.onChangeSynthVolume}
          onChangeDrumsVolume={this.onChangeDrumsVolume}
          synthInstrumentId={this.state.currentSynthId}
          drumsInstrumentId={this.state.currentDrumsId}
          onChangeSynthInstrument={this.onChangeSynthInstrument}
          onChangeDrumsInstrument={this.onChangeDrumsInstrument}
          isUserProSubscribed={this.props.isUserProSubscribed}
          isFetchingSoundHelper={this.state.isFetchingSoundHelper}
          previewIdLoading={this.state.previewIdLoading}
          previewIdPlaying={this.state.previewIdPlaying}
          previewIdType={this.state.previewIdType}
          onPreviewPlayPauseClick={this.onPreviewPlayPauseClick} />

        <LoginModal onCancel={this.onLoginModalBack} isOpened={this.state.isLoginModalOpened} />

        <LimitedModal onCancel={this.onLimitedModalBack} isOpened={this.state.isLimitedModalOpened} text={this.state.limitedText} />
        <Header isStickyTopBarVisible={false}
          isUserLoggedIn={this.props.isUserLoggedIn}
          isUserProSubscribed={this.props.isUserProSubscribed} />

        <div className='generator-mobile-padding-block'>
          <div className='generator-centered-block top-24-12'>
            <SweetRoundButton
              className='generator-instrument-settings-button'
              size='medium' color='purple'
              icon='music' name='Instrument Settings'
              onClick={this.onInstrumentChangeClick} />
            {/* <div className='generator-new-circle'>New!</div> */}
          </div>
          <PianoView
            onTouchStart={this.soundHelper.onPressPianoNote}
            onTouchEnd={this.soundHelper.onReleasePianoNote}
            touchedNotesSet={this.state.touchedNotesSet}
            chord={this.state.progressionJson.chords[this.state.currentChordIndex]} />
          <Spin spinning={this.state.isFetchingApi || this.state.isFetchingSoundHelper}>
            <div className='generator-edit-menu-block'>
              <EditMenuIcon
                tooltip={this.state.isProgressionSaved
                  ? 'Click to remove this chord progression from your Library'
                  : 'Save this chord progression to your Profile -> Library'}
                icon={this.state.isProgressionSaved ? 'heartFilled' : 'heartOutline'}
                label={this.state.isProgressionSaved ? 'Saved' : 'Save'}
                selected={this.state.isProgressionSaved}
                onClick={this.onSaveDeleteProgressionClick} />
              <EditMenuIcon
                tooltip='Download the chords as MIDI file for editing in any DAW (FL Studio, Ableton, Logic Pro X, etc.)'
                icon='midi' label='MIDI'
                onClick={this.onExportMidiClick} />
              <EditMenuIcon
                tooltip='Download this chord progression as MP3 audio file'
                icon='music' label='MP3'
                onClick={this.onExportMp3Click} />
              {/* <EditMenuIcon
                tooltip='Share the link to this chord progression with your friends'
                icon='share' label='Share'
                onClick={this.onShareProgressionClick} /> */}
              <EditMenuIcon
                tooltip='Download chord diagrams as PDF file to print it and use offline'
                icon='print' label='Print'
                onClick={this.onPrintProgressionClick} />
            </div>
            <div className='generator-centered-block'>
              <div className='generator-chord-block-main'>
                {this.state.progressionJson.chords.map((chord, index) => (
                  <ChordBlock key={index}
                    index={index}
                    isSelected={index === this.state.currentChordIndex}
                    chordName={chord.name}
                    degreeName={chord.degree}
                    notes={chord.notes}
                    onBlockClick={() => this.onChordBlockClick(index)}
                    onEditClick={() => this.onChordEditClick(index)} />
                ))}
              </div>
            </div>
          </Spin>

          <SweetModal
            title='Edit Chord'
            visible={this.state.isChordEditOpened}
            onCancel={this.onChordEditModalBack}
            isCompact>
            <h4 className='generator-chord-edit-title'>Note</h4>
            <SweetHorizontalPicker names={keys}
              highlighted={this.state.progressionJson.scale.notes}
              selected={this.state.chordEditKey}
              onSelected={this.onChordEditKeySelected} />
            <h4 style={{ paddingTop: 16 }} className='generator-chord-edit-title'>Chord Type</h4>
            <SweetHorizontalPicker
              larger
              names={chordTypes}
              highlighted={Array.from(chordTypes.keys())}
              selected={this.state.chordEditType}
              onSelected={this.onChordEditTypeSelected} />
            <div style={{ paddingBottom: 12 }}></div>
          </SweetModal>

          <div className='generator-centered-block top-48-24 bottom-24-12'>
            <SweetRoundButton icon={this.state.isPlaying ? 'pause' : 'play'} name={this.state.isPlaying ? 'Pause' : 'Play'}
              onClick={this.onPlayPauseClick} />
            <SweetSlider value={this.state.currentBpm} onChange={this.onChangeBpm} min='70' max='200' title='BPM' />
          </div>
          <div className='generator-centered-block bottom-24-12'>
            <OptionSelect title={'Key'} onChange={this.currentKeySelected} collection={keys} selected={this.state.currentKeyIndex} />
            <OptionSelect title={'Arpeggio'} onChange={this.currentArpeggioSelected} collection={arpeggiosLabels}
              selected={arpeggiosNicknames[this.state.progressionJson.arpeggioId]} maxIndex={this.props.isUserProSubscribed ? 99 : UNLOCKED_ARPS_COUNT} />
            <OptionSelect title={'Drums'} onChange={this.currentDrumsSelected} collection={drumsLabels}
              selected={drumsNicknames[this.state.progressionJson.drumsId]} maxIndex={this.props.isUserProSubscribed ? 99 : UNLOCKED_DRUMS_COUNT} />
          </div>
        </div>

        <div className='generator-centered-block top-24-12 bottom-96-48'>
          <div className='generator-generate-button-container'>
            <div className='generator-generate-button-part left'
              onClick={this.onGenerateClick}>
              <i className='icon-shuffle icon center-vertical'></i>
              <div className='name center-vertical'>Generate
                <div>{this.state.generatorMode}</div>
              </div>
            </div>
            <Tooltip
              placement='right'
              title='Change generator settings'>
              <div className='generator-generate-button-part right'
                onClick={this.onGeneratorSettingsClick}>
                <i className='icon-settings icon center-vertical'></i>
              </div>
            </Tooltip>
          </div>
        </div>

        <SweetModal
          title='Generator Settings'
          visible={this.state.isGeneratorSettingsOpened}
          onCancel={this.onGeneratorSettingsModalBack}
          isCompact>
          <div className='generator-settings-modal'>
            <div className='generator-mode-selector'>
              <OptionSelect title='Mode' onChange={this.generatorModeSelected} collection={modeValueDictNames} selected={this.state.generatorMode} />
            </div>
            <div>
              <Checkbox className='generator-checkbox' onChange={this.randomKeyChange} checked={this.state.isGeneratorRandomKey}>Random Key</Checkbox><br />
              <Checkbox className='generator-checkbox' onChange={this.randomBpmChange} checked={this.state.isGeneratorRandomBpm}>Random BPM</Checkbox><br />
              <Checkbox className='generator-checkbox' onChange={this.randomArpeggioChange} checked={this.state.isGeneratorRandomArpeggio}>Random Arpeggio</Checkbox><br />
              <Checkbox className='generator-checkbox' onChange={this.randomDrumsChange} checked={this.state.isGeneratorRandomDrums}>Random Drums</Checkbox><br />
            </div>
          </div>
        </SweetModal>
        <WelcomeText />
        <MobileApps />
        <Footer />
      </React.Fragment >
    )
  }
}

export default withUserAgent(Generator)