/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import * as LogHelper from '../helper/LogHelper'
import CoolButton from './CoolButton'




const SidePopupGenerator = () => {
  const item = 'sidead'

  const [isVisible, setVisible] = useState(true)

  const img = 'new-generator.jpg'
  const adUrl = 'https://chordchord.com/generator?utm_campaign=legacy&utm_medium=sidead&utm_source=legacy.chordchord.com'
  const rel = 'noopener'
  const target = '_blank'

  return <>
    {isVisible && <div className={'side-popup-div right'}>
      <div className='inside-div'>
        <div onClick={() => setVisible(false)} style={{
          paddingRight: 12,
          paddingTop: 8,
          cursor: 'pointer',
          right: 0,
          top: 0,
          position: 'absolute',
          color: '#bebebe',
        }}>X</div>
        {/* <div className='title-div'><b>Guitar &amp; Uku Tuner</b> by ChordChord</div> */}
        <img className='side-popup-image' src={`/static/images/loot/${img}`}>
        </img>
        <a href={adUrl} target={target} rel={rel}
          onClick={() => LogHelper.adClicked(item, img)}>
          <CoolButton />
        </a>
        <div />
      </div>
    </div >}
  </>
}

export default SidePopupGenerator
