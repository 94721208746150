/* eslint-disable react/prop-types */
import React from 'react'
import SweetSlider from './SweetSlider'
import SweetModal from './SweetModal'
import { Spin } from 'antd'
import { SynthIcon, DrumsIcon } from './Icons'
import { SynthInstruments, DrumsInstruments, SYNTH_TYPE, DRUMS_TYPE, INSTRUMENT_TYPES } from '../helper/Instruments'

const SYNTH_INSTRUMENTS_ARRAY = Object.values(SynthInstruments).sort((a, b) => a.title > b.title ? 1 : -1)
const DRUMS_INSTRUMENTS_ARRAY = Object.values(DrumsInstruments).sort((a, b) => a.title > b.title ? 1 : -1)
const SYNTH_IDS = SYNTH_INSTRUMENTS_ARRAY.map(obj => obj.id)
const DRUMS_IDS = DRUMS_INSTRUMENTS_ARRAY.map(obj => obj.id)

export default class InstrumentModal extends React.PureComponent {
  state = {
    instrumentType: SYNTH_TYPE,
  }

  render() {
    let IDS = SYNTH_IDS
    let instruments = SynthInstruments
    let onChangeInstrument = this.props.onChangeSynthInstrument
    let selectedId = this.props.synthInstrumentId

    if (this.state.instrumentType === DRUMS_TYPE) {
      IDS = DRUMS_IDS
      instruments = DrumsInstruments
      onChangeInstrument = this.props.onChangeDrumsInstrument
      selectedId = this.props.drumsInstrumentId
    }

    return (
      <SweetModal
        title='Instrument Settings'
        visible={this.props.isOpened}
        onCancel={this.props.onCancel}
        withPadding={false}>
        <Spin spinning={this.props.isFetchingSoundHelper}>
          <div className='instrument-modal-list-container'>
            <div className='instrument-modal-list-inner left'>
              <InstrumentTypeItem
                isSelected={this.state.instrumentType === SYNTH_TYPE}
                type={SYNTH_TYPE} volume={this.props.synthVolume}
                onChange={this.props.onChangeSynthVolume}
                onClick={() => { this.setState({ instrumentType: SYNTH_TYPE }) }}
              />
              <InstrumentTypeItem
                isSelected={this.state.instrumentType === DRUMS_TYPE}
                type={DRUMS_TYPE} volume={this.props.drumsVolume}
                onChange={this.props.onChangeDrumsVolume}
                onClick={() => { this.setState({ instrumentType: DRUMS_TYPE }) }} />
            </div>
            <div className='instrument-modal-list-inner right'>
              {IDS.map(id =>
                <InstrumentItem
                  key={id}
                  title={instruments[id].title}
                  isPremium={instruments[id].isPremium}
                  isSelected={id === selectedId.toString()}
                  isLoading={this.props.previewIdLoading === id && this.props.previewIdType === this.state.instrumentType}
                  isPlaying={this.props.previewIdPlaying === id && this.props.previewIdType === this.state.instrumentType}
                  onClick={() => onChangeInstrument(id, instruments[id])}
                  onPlayPauseClick={() => this.props.onPreviewPlayPauseClick(this.state.instrumentType, id)} />
              )}
            </div>
          </div>
        </Spin>
      </SweetModal >
    )
  }
}

class InstrumentTypeItem extends React.PureComponent {
  static defaultProps = {
    isSelected: false,
  }

  render() {
    const iconColor = this.props.isSelected ? 'white' : 'rgba(0, 0, 0, 0.7)'
    return (
      <div onClick={this.props.onClick} className={`instrument-type-bullet ${this.props.isSelected ? 'selected' : ''}`}>
        <div className='instrument-type-inner'>
          <div id='title'>
            {INSTRUMENT_TYPES[this.props.type]}
          </div>
          <div id='icon'>
            {this.props.type === 'synth'
              ? <SynthIcon color={iconColor} width={32} height={32} />
              : <DrumsIcon color={iconColor} width={32} height={32} />}
          </div>

          <SweetSlider
            className='instrument-modal-audio-slider'
            textColor={this.props.isSelected ? '#FFF' : 'rgba(0, 0, 0, 0.65)'}
            sliderColor={this.props.isSelected ? 'rgb(90, 183, 255)' : '#1592e6'}
            dropShadow={!this.props.isSelected}
            onChange={this.props.onChange}
            value={this.props.volume} min={0} max={100} title='Volume' />
        </div>
        {/* <i id='chevron-icon' className='icon-chevron-right' /> */}
      </div>
    )
  }
}


class InstrumentItem extends React.PureComponent {
  static defaultProps = {
    isSelected: false,
    isLoading: false,
    isPlaying: false,
    isPremium: false,
  }

  handlePlayPauseClick(event) {
    event.stopPropagation()
    this.props.onPlayPauseClick()
  }

  render() {
    const iconClass = this.props.isLoading ? 'icon-spinner' : this.props.isPlaying ? 'icon-pause' : 'icon-play'
    return (
      <div onClick={this.props.onClick} className={`instrument-bullet ${this.props.isSelected ? 'selected' : ''}`}>
        <i id='icon' onClick={(event) => this.handlePlayPauseClick(event)} className={iconClass} />
        <div>
          <div id='title'>{this.props.title}</div>
          <div id='subtitle' className={this.props.isPremium ? 'premium' : ''}>
            {this.props.isPremium ? 'Premium' : 'Free'}</div>
        </div>
      </div>
    )
  }
}

