/* eslint-disable react/prop-types */
import React from 'react'
import SweetModal from './SweetModal'
import * as LogHelper from '../helper/LogHelper'

class LoginModal extends React.PureComponent {
  render() {
    let androidLink = 'https://play.google.com/store/apps/details?id=com.chordchord.generator&referrer=utm_source%3Dchordchord%26utm_medium%3Dmobile_apps_modal'
    let iosLink = 'https://apps.apple.com/app/chordchord/id1489041024?pt=chordchord&ct=mobile_apps_modal'
    return (
      <SweetModal
        title='Mobile App Is Finally Available!'
        visible={this.props.isOpened}
        onCancel={this.props.onCancel}>
        <div className='centered'>
          <img width={120} height={120} style={{ marginBottom: 24 }} src='/static/images/logo_icon.png' />
        </div>
        <h2 style={{ textAlign: 'center', marginBottom: 16 }}>Download ChordChord Mobile App Now!</h2>
        <div style={{ padding: 0 }} className='mobile-apps-buttons'>
          <a target='_blank' rel='noopener noreferrer' href={androidLink} onClick={() => { this.sendGoogleAnalytics(androidLink) }}>
            <img src='/static/images/google_play.png' />
          </a>
          <a target='_blank' rel='noopener noreferrer' href={iosLink} onClick={() => { this.sendGoogleAnalytics(iosLink) }}>
            <img src='/static/images/app_store.png' />
          </a>
        </div>
      </SweetModal >
    )
  }

  sendGoogleAnalytics(value) {
    LogHelper.logCategory('Link', value)
  }
}

export default LoginModal