/* eslint-disable react/prop-types */
import React from 'react'
import { Select } from 'antd'

class OptionSelect extends React.PureComponent {
  render() {
    let maxIndex = 999
    if (this.props.maxIndex) {
      maxIndex = this.props.maxIndex
    }

    return (
      <Select
        style={{ width: 120, marginRight: 6, marginLeft: 6 }}
        value={this.props.selected}
        onChange={this.props.onChange}>
        <Select.OptGroup label={this.props.title}>
          {this.props.collection.map((element, index) => {
            return <Select.Option className={(index >= maxIndex && 'generator-locked-option')} key={index} value={index}>{element}</Select.Option>
          })}
        </Select.OptGroup>
      </Select>
    )
  }
}

export default OptionSelect