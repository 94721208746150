/* eslint-disable react/prop-types */
import React from 'react'
import { Input } from 'antd'

import {
  FacebookShareButton, FacebookIcon,
  TwitterShareButton, TwitterIcon,
  TelegramShareButton, TelegramIcon,
  WhatsappShareButton, WhatsappIcon,
  RedditShareButton, RedditIcon,
  EmailShareButton, EmailIcon,
} from 'react-share'
import SweetModal from './SweetModal'

class ShareModal extends React.PureComponent {
  render() {
    return (
      <SweetModal
        title='Preview Link'
        visible={this.props.isOpened}
        onCancel={this.props.onCancel}>
        <h2 style={{ textAlign: 'center' }}>Share this link with your friends!</h2>
        <Input value={this.props.url} />
        <div className='share-modal-icons'>
          <FacebookShareButton
            url={this.props.url}>
            <FacebookIcon
              size={32}
              round />
          </FacebookShareButton>
          <TwitterShareButton
            url={this.props.url}>
            <TwitterIcon
              size={32}
              round />
          </TwitterShareButton>
          <TelegramShareButton
            url={this.props.url}>
            <TelegramIcon
              size={32}
              round />
          </TelegramShareButton>
          <WhatsappShareButton
            url={this.props.url}>
            <WhatsappIcon
              size={32}
              round />
          </WhatsappShareButton>
          <RedditShareButton
            url={this.props.url}>
            <RedditIcon
              size={32}
              round />
          </RedditShareButton>
          <EmailShareButton
            url={this.props.url}>
            <EmailIcon
              size={32}
              round />
          </EmailShareButton>
        </div>
      </SweetModal>
    )
  }
}

export default ShareModal