/* eslint-disable react/prop-types */
import React from 'react'

class SynthIcon extends React.PureComponent {
    render() {
        return (
        <svg width={this.props.width} height={this.props.height} 
        fill={this.props.color}
        version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg'x='0px' y='0px'
        viewBox='0 0 64 64' xmlSpace='preserve'>
  <g>
		<path d="M57,7V57H7V7H57 M59.3,3.4H4.7C4,3.4,3.4,4,3.4,4.7v54.5c0,0.7,0.6,1.3,1.3,1.3h54.5c0.7,0,1.3-0.6,1.3-1.3V4.7
			C60.6,4,60,3.4,59.3,3.4L59.3,3.4z"/>
	</g>
	<rect x="21.8" y="36.4" width="2.7" height="20.7"/>
	<rect x="39.9" y="36.4" width="2.7" height="20.7"/>
	<g>
		<path d="M27,6.9v26.9h-7.7V6.9H27 M29.7,4.2H16.6V34c0,1.3,1.1,2.5,2.5,2.5h8c1.3,0,2.5-1.1,2.5-2.5V4.2L29.7,4.2z"/>
	</g>
	<g>
		<path d="M45.1,6.9v26.9h-7.7V6.9H45.1 M47.7,4.2H34.7v30.3c0,1.1,0.9,2,2,2h9c1.1,0,2-0.9,2-2L47.7,4.2L47.7,4.2z"/>
	</g>
   </svg>
   )
  }
}

class DrumsIcon extends React.PureComponent {
    render() {
        return (
        <svg width={this.props.width} height={this.props.height} 
        fill={this.props.color}
        version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg'x='0px' y='0px'
        viewBox='0 0 60 60' xmlSpace='preserve'>
    <g>
	<g id="Oval-888_4_">
		<path d="M30,59.3c-18.1,0-27.9-6.1-27.9-11.9c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6c0,4,10.5,8.6,24.6,8.6s24.6-4.5,24.6-8.6
			c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6C57.9,53.2,48.1,59.3,30,59.3z"/>
	</g>
	<g id="Line_4_">
		<rect x="54.6" y="12.5" width="3.3" height="35"/>
	</g>
	<g id="Line_2_">
		<rect x="2.1" y="12.5" width="3.3" height="34.9"/>
	</g>
	<g id="Oval-888_2_">
		<path d="M30,24.4c-18.1,0-27.9-6.1-27.9-11.9S11.9,0.7,30,0.7c18.1,0,27.9,6.1,27.9,11.9S48.1,24.4,30,24.4z M30,4
			C15.5,4,5.4,8.5,5.4,12.5s10.1,8.6,24.6,8.6c14.5,0,24.6-4.5,24.6-8.6S44.5,4,30,4z"/>
	</g>
	<g id="Path-2097_1_">
		<path d="M18.8,57.3c-0.1,0-0.1,0-0.2,0c-0.8-0.1-1.4-0.7-1.4-1.5L15,28.7l-8.7,21C6,50.5,5,50.9,4.2,50.5
			c-0.8-0.3-1.2-1.3-0.9-2.1l11.3-27.3c0.3-0.7,1-1.1,1.8-1c0.8,0.1,1.3,0.7,1.4,1.5l2.1,26.3l9-25.2c0.2-0.6,0.8-1.1,1.5-1.1
			c0.7,0,1.3,0.4,1.6,1l11.2,26l1.7-26.8c0-0.8,0.6-1.4,1.4-1.5c0.8-0.1,1.5,0.4,1.8,1.1l9.1,26.5c0.3,0.8-0.2,1.8-1,2.1
			c-0.9,0.3-1.8-0.2-2.1-1l-6.4-18.7l-1.6,25.5c0,0.8-0.6,1.4-1.4,1.5c-0.8,0.1-1.5-0.3-1.8-1L30.5,27.7L20.3,56.2
			C20.1,56.9,19.5,57.3,18.8,57.3z"/>
	</g>
</g>
   </svg>
   )
  }
}

export { 
    SynthIcon, 
    DrumsIcon,
}