/* eslint-disable react/prop-types */
import React from 'react'
import SweetModal from './SweetModal'

class LimitedModal extends React.PureComponent {
  render() {
    return (
      <SweetModal
        className='limited-modal'
        visible={this.props.isOpened}
        onCancel={this.props.onCancel}
        isCompact isLight>
        <img src='/static/images/chordchord_pro.png'></img>
        <div id='question'>{this.props.text}</div>
        <div id='caption'>Subscribe to 7-day free trial of ChordChord Generator! Any Generator subscription plan unlocks all features of ChordChord Legacy.</div>
        <a href='https://chordchord.com' target='_blank' id='button' rel="noreferrer"><div>Try ChordChord Generator</div></a>
      </SweetModal>
    )
  }
}

export default LimitedModal