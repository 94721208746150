
export const floatToInt16Pcm = (arrayBuffer) => {
  let input = new Float32Array(arrayBuffer)
  let output = new Int16Array(arrayBuffer.length)
  for (let i = 0; i < input.length; i++) {
    const s = Math.max(-1, Math.min(1, input[i]))
    output[i] = (s < 0 ? s * 0x8000 : s * 0x7FFF)
  }
  return output
}

export const saveBlob = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

export const floatBufferToBlobMp3 = (buffer, fileName, callback) => {
  import('lamejstmp').then(lamejs => {
    const mp3Data = []
    const mp3encoder = new lamejs.Mp3Encoder(2, 44100, 192)
    let mp3Tmp = mp3encoder.encodeBuffer(
      floatToInt16Pcm(buffer.getChannelData(0)),
      floatToInt16Pcm(buffer.getChannelData(1)),
    )
    mp3Data.push(mp3Tmp)
    mp3Tmp = mp3encoder.flush()
    mp3Data.push(mp3Tmp)

    const blob = new Blob(mp3Data, { type: 'audio/mp3' })
    saveBlob(blob, fileName)
    callback()
  })
}

export const floatBufferToBlobWav = (buffer, fileName, callback) => {
  const worker = new Worker('/static/js/recorderWorker.js')

  worker.postMessage({
    command: 'init',
    config: { sampleRate: 44100 },
  })

  worker.postMessage({
    command: 'record',
    buffer: [
      buffer.getChannelData(0),
      buffer.getChannelData(1),
    ],
  })

  worker.postMessage({
    command: 'exportWAV',
    type: 'audio/wav',
  })

  worker.onmessage = (event) => {
    const blob = event.data
    saveBlob(blob, fileName)
    callback()
  }
}
