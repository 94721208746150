/* eslint-disable react/prop-types */
import React from 'react'
class SweetHorizontalPicker extends React.PureComponent {

  render() {
    return (
      <div className='sweet-horizontal-picker-container'>
        {this.props.names.map((element, index) => {
          return (
            <div className={(this.props.selected === index ? 'selected' : '')
              + (this.props.highlighted ? (this.props.highlighted.includes(index) ? ' highlighted' : '') : '')
              + (this.props.larger ? ' larger' : '')}
              onClick={() => this.props.onSelected(index)}
              key={index}>
              {element}
            </div>
          )
        })}
      </div>
    )
  }
}

export default SweetHorizontalPicker