/* eslint-disable react/prop-types */
import React from 'react'
import { Tooltip } from 'antd'

class ChordBlock extends React.PureComponent {
  render() {
    const notesString = this.props.notes.map(note => {
      return note.name
    }).join(' ')
    let selectedClass = this.props.isSelected ? ' selected' : ''
    selectedClass += this.props.index === 0 ? ' left' : this.props.index === 3 ? ' right' : ''

    return (
      <Tooltip placement='bottom' title={`Notes in chord: ${notesString}`} mouseLeaveDelay={0}>
        <div onClick={this.props.onBlockClick} className={'generator-chord-block-div' + selectedClass}>
          <div className={'chord' + selectedClass}>{this.props.chordName}</div>
          <div className={'degree' + selectedClass}>{this.props.degreeName}</div>
          <i className={'icon-edit' + selectedClass} onClick={this.props.onEditClick}></i>
        </div >
      </Tooltip >
    )
  }
}
export default ChordBlock