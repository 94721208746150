
/* eslint-disable react/prop-types */
import React from 'react'
import { Tooltip } from 'antd'
class EditMenuIcon extends React.PureComponent {
  static defaultProps = {
    className: '',
    label: '',
    selected: false,
    tooltip: '',
  }

  icons = {
    play: 'icon-play icon',
    pause: 'icon-pause icon',
    random: 'icon-shuffle icon',
    settings: 'icon-settings icon',
    midi: 'icon-midi icon',
    cloudSave: 'icon-cloud-save icon',
    share: 'icon-share icon',
    lock: 'icon-lock icon',
    edit: 'icon-edit icon',
    times: 'icon-times icon',
    music: 'icon-music icon',
    stop: 'icon-stop icon',
    record: 'icon-record icon',
    heartFilled: 'icon-heart-filled icon',
    heartOutline: 'icon-heart-outline icon',
    print: 'icon-print icon',
  }

  render() {
    let icon = ''
    if (this.props.icon in this.icons) {
      icon = this.icons[this.props.icon]
    }

    const classes = ['edit-menu-icon', this.props.className]
    this.props.hiddenOnMobile && classes.push('hidden-on-mobile')
    this.props.selected && classes.push('selected')
    const classesString = classes.join(' ')

    let textClasses = ['name']
    const textClassesString = textClasses.join(' ')

    return (
      <Tooltip
        placement='top'
        title={this.props.tooltip}
        mouseEnterDelay={0.2}
        mouseLeaveDelay={0}
        overlayStyle={{ width: 200 }}>
        <div
          onClick={this.props.onClick}
          className={classesString}>
          <i className={icon}></i>
          <div className={textClassesString}>{this.props.label}</div>
        </div >
      </Tooltip >
    )
  }
}

export default EditMenuIcon